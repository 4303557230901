// sass-lint:disable no-important
@import "src/styles/imports";

.guide-wrapper {
    margin: 2em 0 4em;
}

.area-guide {
    max-width: $max-width-text;
    margin: 0 auto !important;
}

.area-blurb p {
    max-width: $max-width-text;
    line-height: 1.7em;
    margin: 0 auto 2em;
}

.area-blurb-heading {
    font-size: 1.75em;
    max-width: $max-width-text;
    margin: 0 auto 1em;
    font-family: $font-family-serif;
    @include mobile {
        font-size: 1.25em;
    }
}
