@import 'src/styles/imports';

.card-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 48px 20px;
  border: 1px solid $color-grey-1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-image: url('/next/assets/images/map-still-image.png');
  background-size: cover;
  text-align: center;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .button {
    font-size: large;
    padding: 15px 20px;
    font-weight: $font-weight-bold;
  }
}

.mobile-card-wrapper {
  min-height: 400px;
}
