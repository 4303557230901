@import 'src/styles/imports';

.grid {
  display: grid;
  padding: 1.25em 0;
  grid-template-columns: minmax(0, 26 * 16 * 1em);
  grid-gap: .7em;

  @include tablet {
    grid-template-columns: repeat(2, minmax(0, 26 * 16 * 1em));
  }

  @include desktop {
    grid-template-columns: repeat(4, minmax(0, 26 * 16 * 1em));
    grid-gap: 1em;
  }

  @media (min-width: $breakpoint-tablet) and (max-width: $max-width-app) {
    grid-template-columns: repeat(3, minmax(0, 26 * 16 * 1em));
  }

  @media (min-width: $max-width-app + 300) {
    grid-gap: 1.5em;
  }
}

.breadcrumbs {
  padding-top: 1.75em;
  @include flex-center;

  a {
    font-size: 16px;
    color: $color-grey-9;
    font-family: $font-family-sans-serif;
  }

  a:hover {
    color: $color-grey-6;
  }

  .active {
    color: $color-grey-6;
    font-weight: bold;
  }
}
