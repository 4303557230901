@import 'src/styles/imports';

.component {
  display: flex;
  margin-top: 1em;
  flex-direction: row;
  justify-content: space-between;

  .column {
    display: flex;
    width: 100%;
    line-height: 1.55em;
    padding: 1em;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .icon-container {
      display: flex;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-color: $color-sand-very-light;
      align-items: center;

      &.exp-theme {
        background-color: $color-transparent;
      }

      span {
        font-size: 34px;
        width: 100%;
      }
    }

    .sub-section {
      margin-top: .5em;

      .number {
        font-size: 24px;
        font-family: $font-family-serif;
        font-weight: 500;
      }

      .text {
        font-size: 14px;
        color: $color-black-65;
        letter-spacing: .25px;
      }
    }
  }

  .column:nth-child(odd) {
    background-color: $color-grey-7;

    &.exp-theme {
      border-radius: $border-radius-large;
      background-color: $color-dark-navy-bg;
    }
  }


  @media (max-width: $breakpoint-mobile) {
    .column {
      line-height: 1.3em;
      padding: 5px 1px;
    }
  }
}
