// sass-lint:disable no-important
@import 'src/styles/imports';

.related-searches {
  max-width: $max-width-text;
  margin: auto !important;
}

.component {
  padding-bottom: 2em;

  .section-container:not(:first-child) {
    margin-top: 2em;
  }

  .single-data-card-section {
    display: flex;
    padding-bottom: 1em;
    border-bottom: 1px solid $color-black-10;
    margin-top: 1em;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  a {
    color: var(--themes-colors-primary-link-color);

    &:hover {
      color: var(--themes-colors-tertiary-link-color);
    }

    &:focus {
      color: var(--themes-colors-quartenary-link-color);
    }
  }

  h3 {
    font-size: 1.25em;
    font-family: $font-family-serif;
    font-weight: 500;
  }

  p {
    font-size: .9375em;
    line-height: 1.7em;
    padding-top: 1em;
  }
}
