@import 'src/styles/imports';

.component {
  padding: 0 .8em;

  .select-field {
    position: relative;
    width: 150px;
    margin: .5em 0;
    white-space: pre;

    svg {
      position: absolute;
      top: calc(50% - .2em);
      right: .5em;
    }

    select {
      font-size: 12pt;
      width: 100%;
      line-height: 36px;
      padding: 0 30px 0 12px;
      border: 1px solid $color-grey-3;
      border-radius: $border-radius-small;
      background: $color-white;
      font-family: $font-family-sans-serif;
      white-space: pre;
      appearance: none;
    }
  }
}
