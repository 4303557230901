@import 'src/styles/imports';

.component {
  display: flex;
  box-shadow: $color-box-shadow;
  width: 165px;
  height: 85px;
  padding: .75em .6em;
  border-radius: 16px;
  margin: 1em .5em;
  flex-direction: column;

  .field-name {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: .5em;
    color: $color-black-65;
    font-weight: 600;
  }

  .value {
    font-size: 32px;
    line-height: 41px;
    color: $color-grey-6;
    font-family: $font-family-serif;
    font-weight: 500;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 130px;

    .field-name {
      font-size: 12px;
    }

    .value {
      font-size: 28px;
    }
  }
}
