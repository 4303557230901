@import 'src/styles/imports';

.component {
  @include desktop {
    overflow: auto;
    background-color: $color-white;
  }

  @include mobile {
    min-height: 30.7em;
  }

  .heading {
    font-size: 20px;
    line-height: 25px;
    margin: 24px;
    color: $color-grey-6;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    letter-spacing: .15px;
  }

  .heading-border {
    border-top: 1px solid $color-grey-4;

    @include desktop {
      margin-bottom: 24px;
    }
  }

  .layout-container-without-separators {
    display: grid;
    align-items: baseline;

    & .row-border {
      border-top: 1px solid $color-grey-4;
      grid-column: 1 / 5;
    }

    @include desktop {
      padding-bottom: 80px;
      grid-row-gap: 24px;
      grid-column-gap: 40px;
      grid-template-columns: repeat(4, 1fr);
      @include layout-container;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .layout-container-with-separators {
    display: grid;
    align-items: baseline;

    @include desktop {
      padding-bottom: 20px;
      grid-row-gap: 24px;
      grid-column-gap: 10px;
      grid-template-columns: repeat(9, 1fr);
      @include layout-container;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .footer-group-collapsable {
    display: inline-block;

    h2 {
      font-size: .7em;
      position: relative;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 250px;
      line-height: 1.5em;
      margin-bottom: 8px;
      color: $color-grey-6;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 700;
      text-overflow: ellipsis;
      letter-spacing: .44px;
    }

    a {
      font-size: 14px;
      display: inline-block;
      line-height: 15px;
      margin-bottom: 20px;
      color: $color-grey-6;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 400;
      letter-spacing: .1px;

      @include desktop {
        overflow: hidden;
        overflow-wrap: normal;
        width: 250px;
        text-align: center;
      }
    }

    @include desktop {
      width: 270px;
    }

    .links-collapsable {
      border: 0;

      h3 {
        font-size: 16px;
        position: relative;
        line-height: 24px;
        color: $color-grey-6;
        font-family: $font-family-sans-serif;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        letter-spacing: .1px;
        text-wrap: nowrap;
      }
    }

    .links-container {
      display: grid;
      align-items: baseline;
    }
  }

  .footer-group-not-collapsable {
    display: inline-block;

    h2 {
      font-size: 1.125em;
      position: relative;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 250px;
      line-height: 1.5em;
      margin-bottom: 8px;
      color: $color-grey-6;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 700;
      text-overflow: ellipsis;
      letter-spacing: .44px;
    }

    a {
      font-size: 16px;
      display: inline-block;
      line-height: 32px;
      color: $color-grey-6;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 400;
      letter-spacing: .44px;

      @include desktop {
        overflow: hidden;
        width: 250px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @include desktop {
      width: 250px;
    }

    .links-collapsable {
      border: 0;
      margin: 10px 20px;

      h3 {
        font-size: 16px;
        position: relative;
        line-height: 24px;
        color: $color-grey-6;
        font-family: $font-family-sans-serif;
        font-style: normal;
        font-weight: 700;
        letter-spacing: .44px;
      }
    }

    .links-container {
      display: grid;
      align-items: baseline;
    }
  }

  .column-separator {
    width: 1px;
    height: 50px;
    padding-top: 1.5em;
    padding-bottom: 2em;
    margin-top: 2em;
  }
}
