// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  padding-bottom: 2em;
  flex-direction: column;
  @include layout-container;
}

.search-nav {
  display: none;

  @include not-mobile {
    display: block;
  }
}

.title-sort-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-title {
  font-size: 1.75em;
  position: relative;
  margin: 1.5em 0;
  text-transform: capitalize;
  @include mobile {
    font-size: 1.25em;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 111px;
    height: .2em;
    background-color: var(--themes-colors-primary);
  }
}

.sort-by {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;

  @include not-mobile {
    width: 12rem;
    align-items: flex-start;
    flex-direction: column;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.mobile-filter-btn {
  display: inline-block;
  margin-top: -1em;
  margin-left: 1em;

  @include not-mobile {
    display: none;
  }
}

.no-listings-indicator {
  opacity: .6;
}

.guide-wrapper {
  margin: 2em 0 4em;
}

.area-guide {
  max-width: $max-width-text;
  margin: 0 auto !important;
}

.area-blurb {
  max-width: $max-width-text;
  line-height: 1.7em;
  margin: 0 auto 2em;
}
