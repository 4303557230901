@import 'src/styles/imports';

.component {
  padding: 0 1rem;

  label {
    display: block;
    margin-bottom: .5em;
    font-family: $font-family-sans-serif;
  }
}
