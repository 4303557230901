@import 'src/styles/imports';

.component {
  display: flex;
  max-width: 45em;
  min-height: calc(100vh - 80px);
  padding: 10em 0;
  margin: auto;
  flex-direction: row;
  justify-content: space-around;

  @include not-desktop {
    padding: 0;
    align-items: center;
    flex-direction: column;
    justify-content: start;
  }

  .pin-wrapper {
    position: relative;
    margin-right: 2.8em;
    animation: bounceIn .8s;


    @include not-desktop {
      margin-right: 0;

      svg {
        scale: .6;
      }
    }

    span {
      font-size: 3.6em;
      position: absolute;
      z-index: $z-index-exp-header-text;
      top: 12%;
      right: 19%;
      color: $color-grey-2;

      @include not-desktop {
        font-size: 2.2em;
        top: 30%;
        right: 30%;
      }
    }
  }

  .text-wrapper {
    position: relative;
    bottom: 1.3em;
    display: flex;
    width: 83%;
    text-align: left;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    .heading {
      font-size: 2.9em;
      width: 100%;
      margin: 1.3em 0 .5em;
      text-align: left;

      @include not-desktop {
        text-align: center;
      }

      @include mobile {
        font-size: 2em;
        text-align: center;
      }
    }

    .content {
      font-size: 1.1em;
      line-height: 1.9em;
      text-align: left;

      p:first-child {
        font-size: 1.25em;
        padding: .7rem 0 .9em;

        @include not-desktop {
          font-size: 1.1em;
        }
      }

      p:last-child {
        font-size: .9em;
      }

      a {
        color: var(--themes-colors-primary-link-color);
      }
    }
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
    transform-origin: bottom;
  }

  60% {
    opacity: 1;
    transform: translateY(0) scaleY(.93);
  }

  80% {
    transform: scaleY(1.07);
  }

  100% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
}
