@import 'src/styles/imports';

.component {
  padding: 2.5em;
  flex-direction: column;
  @include flex-center;

  svg {
    width: 3em;
    height: 3em;

    path,
    circle {
      fill: $color-grey-5;
    }
  }

  .message {
    font-size: 1.375em;
    margin-top: .75em;
    font-family: $font-family-serif;
  }
}
