@import 'src/styles/imports';

.card-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 48px 20px;
  border: 1px solid $color-grey-1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .check-wrapper {
    position: relative;
    width: 55px;
    height: 51px;
    margin-top: 2em;
  }

  .house-image-wrapper {
    position: relative;
    width: 136px;
    height: 104px;
  }

  .heading {
    font-size: 20px;
    margin: 28px 0 10px;
    font-weight: var(--themes-fonts-heading-bold-weight);

    &.exp-theme {
      font-weight: 700;
    }
  }

  p {
    font-size: 14px;
    max-width: 320px;
    line-height: 20px;
  }

  button {
    font-size: 14px;
    width: 100%;
    padding: 12px 16px;
    border-radius: var(--themes-buttons-border-radius-large);
    margin-top: 28px;
    font-weight: 500;
  }
}

.mobile-card-wrapper {
  .inner-wrapper > * {
    margin: 1em;
  }
}
