@import 'src/styles/imports';

.component {
  font-size: 9.5pt;
  position: relative;
  display: inline-block;
  padding: .75em 0 .75em 28px;
  margin-right: 1.5em;
  text-align: left;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    display: inline-block;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  &::before {
    left: 0;
    box-shadow: inset 0 1px 2px $color-black-25;
    width: 22px;
    height: 22px;
    border: 1px solid $color-grey-3;
    background: $color-white;
  }
}

.active {
  &::after {
    left: 5px;
    box-shadow: 0 1px 2px $color-black-25;
    width: 12px;
    height: 12px;
    background: var(--themes-colors-primary);
  }
}
